<script>
    let {
        heading = '',
        description = '',
        logo = '',
        userName = '',
        svg = null,
    } = $props()
</script>

<section
    data-test="loggedin-provider-container"
    class="-mx-2 mb-6 rounded-md bg-[#DCDCDC] px-2 pb-3 pt-2 dark:bg-[#505050]"
>
    <span class="block text-left text-sm font-medium">{heading}</span>
    <div
        class="text-charcoal mt-2 flex flex-col items-start justify-center rounded-md px-2 dark:text-[#D4D4D4]"
    >
        <div class="mt-1 inline-flex items-center gap-x-2.5">
            {#if svg}
                {@render svg()}
            {:else}
                <span
                    class="inline-flex items-center justify-center rounded-sm"
                >
                    <span class="managed-rounded-square-sm -ml-0">
                        <img
                            src={logo}
                            alt="logo"
                            class="w-4.5 max-h-[18px] object-contain"
                        />
                    </span>
                </span>
            {/if}
            <span>
                {userName}
            </span>
        </div>
    </div>
    {#if description}
        <span class="mt-4 block text-left text-sm italic">{description}</span>
    {/if}
</section>
