<script>
    import logins from '$svr/providers/logins.json'
    import { slide } from 'svelte/transition'
    import { _, locale } from 'svelte-i18n'
    import {
        DEFAULT_MANAGED_LOGO,
        SLIDE_ANIM_MS,
        DISCO_DEBOUNCE_MS,
    } from '$src/constants.js'
    import { mastodonCache } from '$utils/mastodon.js'
    import { getDisplay, isValidDomain, preventDefault } from '$utils/common.js'
    import { getMastodonDiscovery } from '$utils/api.js'
    import Dropdown from '$lib/Dropdown.svelte'
    import SpinnerIcon from '$lib/icon/SpinnerIcon.svelte'
    import PasskeyIcon from '$lib/icon/PasskeyIcon.svelte'
    import MailIcon from '$lib/icon/MailIcon.svelte'

    let {
        login = false,
        mastodonExpanded = $bindable(false),
        hideMastodonDropdown = false,
        autofocus = false,
        provider = {},
        prefix = '',
        hideusername = false,
        label = '',
        loading = false,
        disabled = false,
        strikethrough = false,
        logo = '',
        managed = false,
        onclick = () => {},
        ethereum = () => {},
    } = $props()

    const errors = $state({
        invalidDomain: false,
        mastodonServerNotFound: false,
        knowLogin: false,
    })
    let value = $state('')
    let mastodonServerButtonDisabled = $state(true)
    let checkMastodonServerAjax = $state(false)

    const display = $derived(
        provider.display ||
            logins.find((i) => i.slug === provider.slug)?.display,
    )
    const isKnownLoginSlug = $derived(
        [...logins, { slug: 'metamask' }, { slug: 'ethereum' }] // because logins.json does not have web3 logins
            .map((i) => i.slug)
            .includes(provider.slug),
    )
    const domain = $derived(value?.split('@')[1] || value)

    $effect(() => {
        if (mastodonExpanded) {
            //reset all states when expanded mastodon dropdown
            value = ''
            checkMastodonServerAjax =
                errors.invalidDomain =
                errors.mastodonServerNotFound =
                errors.knowLogin =
                    false
        }
    })

    let timer
    async function handleMastodonServerInput() {
        checkMastodonServerAjax =
            errors.invalidDomain =
            errors.mastodonServerNotFound =
            errors.knowLogin =
                false
        mastodonServerButtonDisabled = true

        const server = domain

        if (timer) clearTimeout(timer)
        if (Object.keys(mastodonCache).includes(server)) {
            if (login && mastodonCache[server].hello_login) {
                errors.knowLogin = true
            } else {
                mastodonServerButtonDisabled = false
            }
        } else {
            timer = setTimeout(async () => {
                if (!server.length) return
                const invalidDomain = !isValidDomain(server)
                if (invalidDomain) {
                    errors.invalidDomain = true
                    return
                }
                checkMastodonServerAjax = true
                let discoRes
                discoRes = await getMastodonDiscovery(server)
                if (server !== domain) return
                if (discoRes?.software?.name === 'mastodon') {
                    if (discoRes?.metadata?.hello) {
                        errors.knowLogin = true
                    } else {
                        mastodonServerButtonDisabled = false
                    }
                } else {
                    errors.mastodonServerNotFound = true
                    mastodonServerButtonDisabled = true
                }
                checkMastodonServerAjax = false
            }, DISCO_DEBOUNCE_MS)
        }
    }
</script>

{#if provider.slug === 'mastodon' && !provider.user_name}
    {#if hideMastodonDropdown}
        <form
            onsubmit={preventDefault(() => onclick(domain))}
            transition:slide={{ duration: SLIDE_ANIM_MS }}
        >
            <!-- svelte-ignore a11y_autofocus -->
            <input
                autofocus
                type="text"
                name="mastodon-server"
                id="mastodon-server"
                bind:value
                oninput={handleMastodonServerInput}
                autocapitalize="off"
                placeholder="enter your Mastodon server (mastodon.example)"
                class="h-12 w-full bg-transparent px-[16px] sm:px-[18px]"
            />
            {#if errors.invalidDomain}
                <span
                    class="mt-2 block text-left text-red-500"
                    data-test="mastodon-input-error"
                    transition:slide={{ duration: SLIDE_ANIM_MS }}
                    >{$_('Invalid domain')}</span
                >
            {:else if errors.mastodonServerNotFound}
                <span
                    class="mt-2 block text-left text-red-500"
                    data-test="mastodon-input-error"
                    transition:slide={{ duration: SLIDE_ANIM_MS }}
                    >{$_('Mastodon server not found')}</span
                >
            {:else if errors.knowLogin}
                <span
                    class="mt-2 block text-left text-red-500"
                    data-test="mastodon-input-error"
                    transition:slide={{ duration: SLIDE_ANIM_MS }}
                    >{$_('This server uses Hellō for login')}</span
                >
            {:else if checkMastodonServerAjax}
                <div
                    class="mt-2 flex items-center justify-start"
                    transition:slide={{ duration: SLIDE_ANIM_MS }}
                >
                    <SpinnerIcon css="h-5 w-5 text-charcoal dark:text-white" />
                    <span class="ml-2 block text-left opacity-80"
                        >{$_('Checking')}</span
                    >
                </div>
            {/if}
            <button
                data-test="mastodon-continue-btn"
                class="btn-background mt-4 flex w-full items-center justify-center"
                disabled={mastodonServerButtonDisabled}
            >
                Continue
            </button>
        </form>
    {:else}
        <Dropdown
            dataTest="mastodon-btn"
            ariaLabel={$_(`${prefix} {provider}`, {
                values: { provider: display },
            })}
            expanded={mastodonExpanded}
            onclick={() => {
                mastodonExpanded = !mastodonExpanded
            }}
        >
            <div
                class="flex h-12 w-full items-center justify-start gap-x-4 px-4"
            >
                <img
                    src="https://cdn.hello.coop/images/mastodon.svg"
                    alt="Mastodon"
                    class="w-4.5 max-h-[18px]"
                />
                <span class="block text-left" aria-hidden="true">
                    {$_(`${prefix} {provider}`, {
                        values: { provider: display },
                    })}
                </span>
            </div>
            {#if mastodonExpanded}
                <form
                    class="px-4 pb-3 pt-1"
                    onsubmit={preventDefault(() => onclick(domain))}
                    transition:slide={{ duration: SLIDE_ANIM_MS }}
                >
                    <!-- svelte-ignore a11y_autofocus -->
                    <input
                        autofocus
                        type="text"
                        name="mastodon-server"
                        id="mastodon-server"
                        bind:value
                        oninput={handleMastodonServerInput}
                        autocapitalize="off"
                        placeholder="enter your Mastodon server (mastodon.example)"
                        class="h-12 w-full bg-transparent px-[16px] sm:px-[18px]"
                    />
                    {#if errors.invalidDomain}
                        <span
                            class="mt-2 block text-left text-red-500"
                            data-test="mastodon-input-error"
                            transition:slide={{ duration: SLIDE_ANIM_MS }}
                            >{$_('Invalid domain')}</span
                        >
                    {:else if errors.mastodonServerNotFound}
                        <span
                            class="mt-2 block text-left text-red-500"
                            data-test="mastodon-input-error"
                            transition:slide={{ duration: SLIDE_ANIM_MS }}
                            >{$_('Mastodon server not found')}</span
                        >
                    {:else if errors.knowLogin}
                        <span
                            class="mt-2 block text-left text-red-500"
                            data-test="mastodon-input-error"
                            transition:slide={{ duration: SLIDE_ANIM_MS }}
                            >{$_('This server uses Hellō for login')}</span
                        >
                    {:else if checkMastodonServerAjax}
                        <div
                            class="mt-2 flex items-center justify-start"
                            transition:slide={{ duration: SLIDE_ANIM_MS }}
                        >
                            <SpinnerIcon
                                css="h-5 w-5 text-charcoal dark:text-white"
                            />
                            <span class="ml-2 block text-left opacity-80"
                                >{$_('Checking')}</span
                            >
                        </div>
                    {/if}
                    <button
                        data-test="mastodon-continue-btn"
                        class="btn-background mt-4 flex w-full items-center justify-center"
                        disabled={mastodonServerButtonDisabled}
                    >
                        {$_('Continue')}
                    </button>
                </form>
            {/if}
        </Dropdown>
    {/if}
{:else}
    {@const dataTest = [
        //not the provider.managed from logins.json
        (managed || (provider.managed && !provider.claims)) && 'managed',
        provider.slug,
        'btn',
    ]
        .filter(Boolean)
        .join('-')}
    <!-- eg: managed-google-btn -->
    <!-- svelte-ignore a11y_autofocus -->
    <button
        {autofocus}
        {disabled}
        data-test={dataTest}
        class="btn-background group relative flex w-full flex-shrink-0 items-center justify-start overflow-hidden px-4 disabled:cursor-not-allowed disabled:opacity-50"
        class:cursor-not-allowed={disabled}
        class:h-18={provider.user_name && !hideusername}
        onclick={() => {
            if (provider.slug === 'ethereum') ethereum()
            else onclick()
        }}
    >
        {#if loading}
            <SpinnerIcon css="h-5 w-5 block mx-auto" />
        {:else}
            {#if provider.slug === 'ethereum'}
                <img
                    src={provider.wallet?.icon ||
                        'https://cdn.hello.coop/images/ethereum.svg'}
                    alt={provider.wallet?.name || 'Ethereum'}
                    class={provider.user_name && !hideusername
                        ? 'mt-0 max-h-[36px] w-9'
                        : 'w-4.5 max-h-[18px]'}
                />
            {:else if provider.slug === 'passkey'}
                <PasskeyIcon css="mt-0.5 h-4.5 w-4.5" />
                <!-- not the provider.managed from logins.json -->
            {:else if managed || (provider.managed && !provider.claims)}
                {#if provider.user_name && !hideusername}
                    <span class="managed-rounded-square-lg">
                        <img
                            src={logo || provider.logo || DEFAULT_MANAGED_LOGO}
                            alt="logo"
                            class="mt-0 max-h-[36px] w-9 object-contain"
                        />
                    </span>
                {:else}
                    <span class="managed-rounded-square-sm">
                        <img
                            src={logo || provider.logo || DEFAULT_MANAGED_LOGO}
                            alt="logo"
                            class="w-4.5 max-h-[18px]"
                        />
                    </span>
                {/if}
            {:else if logo || isKnownLoginSlug}
                <img
                    src={logo ||
                        `https://cdn.hello.coop/images/${provider.slug}.svg`}
                    alt={display}
                    class="{provider.user_name && !hideusername
                        ? 'mt-0 max-h-[36px] w-9'
                        : 'w-4.5 max-h-[18px]'} object-contain"
                />
            {:else if provider.slug === 'email'}
                <MailIcon size="lg" />
            {:else}
                <!-- Fallback icon -->
                <span
                    class="w-4.5 h-4.5 relative flex items-center justify-center rounded-md bg-[#808080] pl-[1px] text-sm font-medium"
                >
                    ?
                </span>
            {/if}

            {#if provider.user_name}
                <div
                    class="{$locale && $locale.startsWith('ar')
                        ? 'mr-4 text-right'
                        : 'ml-4 text-left'} truncate"
                >
                    <span class="block">
                        {#if provider.slug === 'ethereum'}
                            {$_(`${prefix} {provider}`, {
                                values: { provider: $_('extension') },
                            })}
                        {:else if managed || provider.managed}
                            {$_('Continue with')}
                        {:else}
                            {$_(`${prefix} {provider}`, {
                                values: { provider: getDisplay(provider.slug) },
                            })}
                        {/if}
                    </span>

                    {#if !hideusername}
                        <span class="block truncate">
                            {#if provider.slug === 'ethereum'}
                                {provider.wallet?.name || 'Ethereum'}
                            {/if}

                            {#if provider.slug === 'mastodon'}
                                @{provider.user_name}@{provider.mastodonServer}
                            {:else}
                                {provider.user_name}
                            {/if}
                        </span>
                    {/if}
                </div>
            {:else}
                <span
                    class:line-through={strikethrough}
                    class="block truncate {$locale && $locale.startsWith('ar')
                        ? 'mr-4 text-right'
                        : 'ml-4 text-left'}"
                >
                    {#if label}
                        {label}
                    {:else}
                        {$_(`${prefix} {provider}`, {
                            values: {
                                provider: display,
                            },
                        })}
                    {/if}
                </span>
            {/if}

            <!-- Right arrow icon -->
            <svg
                xmlns="http://www.w3.org/2000/svg"
                class="flex-shrink-0 {$locale && $locale.startsWith('ar')
                    ? 'mr-auto rotate-90'
                    : 'ml-auto -rotate-90'} h-4.5 group-focus:stroke-3 group-hover:stroke-3 transform text-white opacity-80 dark:text-[#d4d4d4] {provider.user_name &&
                !hideusername
                    ? 'mb-5.5'
                    : ''}"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19 9l-7 7-7-7"
                />
            </svg>
        {/if}
    </button>
{/if}
